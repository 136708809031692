.toggle-button {
    display: block;
}

.container-fluid-custom {
  height: 100vh;
}

@media (min-width: 992px) {
    .navbar,
    .navbar-collapse {
      flex-direction: column;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: column;
    }
    .navbar {
      min-width: 25vh;
      height: 100vh;
      align-items: flex-start;
    }

    .toggle-button {
        display: none;
    }

    .container-fluid-custom {
      display: flex;
      flex-direction: row;
      min-width: 100%;
      min-height: 100%;
    }
  }